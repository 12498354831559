import type { AxiosResponse } from 'axios';

import { gtmClient } from '@/services/gtm';

import type {
  AdsConnectorType,
  UpdateAdsCabinetsPayload,
  UpdateAdsCabinetsResponse,
} from '@/services/api';
import { ApiClient } from '../../apiClient';

import type {
  FetchAdsCabinetsResponse,
  FetchAdsCabinetsByConnectorResponse,
  FetchAdsCabinetUnusedHighLevelObjectsPayload,
  FetchAdsCabinetUnusedHighLevelObjectsResponse,
  FetchAvailableAdsCabinetsByConnectorResponse,
  UpdateAdsCabinetsByConnectorPayload,
  DeleteAdsCabinetByConnectorPayload,
  DeleteAdsCabinetByConnectorResponse,
  FetchAdsCabinetsCountByConnectorResponse,
} from './types';

export class CabinetApiClient extends ApiClient {
  async fetchAdsCabinets(payload: {
    spaceId: number;
    projectId: number;
    params?: {
      search?: string;
      limit?: number;
      offset?: number;
      order_by?: string;
      connector_type?: string | string[];
    };
  }): Promise<AxiosResponse<FetchAdsCabinetsResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.get<FetchAdsCabinetsResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets`,
      {
        params: payload.params,
      },
    );

    return res;
  }

  async fetchAdsCabinetsByConnector(payload: {
    spaceId: number;
    projectId: number;
    adsConnectorType: string;
  }): Promise<AxiosResponse<FetchAdsCabinetsByConnectorResponse>> {
    const { spaceId, projectId, adsConnectorType } = payload;

    const res = await this.httpClient.get<FetchAdsCabinetsByConnectorResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}`,
    );

    return res;
  }

  async fetchAdsCabinetsCountByConnector(payload: {
    spaceId: number;
    projectId: number;
    adsConnectorType: AdsConnectorType;
  }): Promise<AxiosResponse<FetchAdsCabinetsCountByConnectorResponse>> {
    const { spaceId, projectId, adsConnectorType } = payload;

    const res =
      await this.httpClient.get<FetchAdsCabinetsCountByConnectorResponse>(
        `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/count`,
      );

    return res;
  }

  async fetchAvailableAdsCabinetsByConnector(payload: {
    spaceId: number;
    projectId: number;
    adsConnectorType: AdsConnectorType;
  }): Promise<AxiosResponse<FetchAvailableAdsCabinetsByConnectorResponse>> {
    const { spaceId, projectId, adsConnectorType } = payload;

    const res =
      await this.httpClient.get<FetchAvailableAdsCabinetsByConnectorResponse>(
        `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/available`,
      );

    return res;
  }

  async fetchAvailableAdsCabinets(payload: {
    spaceId: number;
    projectId: number;
    params?: {
      search?: string;
      limit?: number;
      offset?: number;
      order_by?: string;
      connector_type?: string;
      connector_user_ids?: string;
    };
  }): Promise<AxiosResponse<FetchAvailableAdsCabinetsByConnectorResponse>> {
    const { spaceId, projectId, params } = payload;

    const res =
      await this.httpClient.get<FetchAvailableAdsCabinetsByConnectorResponse>(
        `/api/${spaceId}/${projectId}/ads_cabinets/available`,
        { params },
      );

    return res;
  }

  async fetchAdsCabinetUnusedHighLevelObjects(
    payload: FetchAdsCabinetUnusedHighLevelObjectsPayload,
  ): Promise<AxiosResponse<FetchAdsCabinetUnusedHighLevelObjectsResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetId } = payload;

    const res =
      await this.httpClient.get<FetchAdsCabinetUnusedHighLevelObjectsResponse>(
        `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}/unused_high_level_objects`,
      );

    return res;
  }

  async updateAdsCabinets(
    payload: UpdateAdsCabinetsPayload,
  ): Promise<AxiosResponse<UpdateAdsCabinetsResponse>> {
    const { spaceId, projectId, params } = payload;

    const res = await this.httpClient.patch<UpdateAdsCabinetsResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/patch`,
      params,
    );

    return res;
  }

  async updateAdsCabinetsByConnector(
    payload: UpdateAdsCabinetsByConnectorPayload,
  ): Promise<AxiosResponse<UpdateAdsCabinetsResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetIds } = payload;

    const res = await this.httpClient.post<UpdateAdsCabinetsResponse>(
      `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/update`,
      { cabinet_ids: adsCabinetIds },
    );

    gtmClient.track({
      event: 'user_action',
      event_name: `project_settings_cabinets_${adsConnectorType}_save`,
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async deleteAdsCabinetByConnector(
    payload: DeleteAdsCabinetByConnectorPayload,
  ): Promise<AxiosResponse<DeleteAdsCabinetByConnectorResponse>> {
    const { spaceId, projectId, adsConnectorType, adsCabinetId } = payload;

    const res =
      await this.httpClient.delete<DeleteAdsCabinetByConnectorResponse>(
        `/api/${spaceId}/${projectId}/ads_cabinets/${adsConnectorType}/${adsCabinetId}`,
      );

    return res;
  }
}

export const cabinetApiClient = new CabinetApiClient();

export default { CabinetApiClient, cabinetApiClient };

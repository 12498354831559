export enum AppQueryKey {
  UserMe = 'user-me',
  UserAuthMethod = 'user-auth-method',
  UserSuggest = 'user-suggest',
  Space = 'space',
  Spaces = 'spaces',
  SpaceUsers = 'space-users',
  SpaceUsersInfinite = 'space-users-infinite',
  Projects = 'projects',
  ProjectsInfinite = 'projects-infinite',
  Project = 'project',
  ProjectUsers = 'project-users',
  ProjectConnectorUsers = 'project-connector-users',
  AdsConnectors = 'ads-connectors',
  AdsCabinets = 'ads-cabinets',
  AdsCabinetsInfinite = 'ads-cabinets-infinite',
  AvailableAdsCabinets = 'available-ads-cabinets',
  AdsCabinetUnusedObjects = 'ads-cabinet-unused-objects',
  AdditionalData = 'additional-data',
  AdditionalDataTypes = 'additional-data-types',
  Dashboard = 'dashboard',
  MagicLinks = 'magic-links',
  Segments = 'segments',
  SegmentObjects = 'segment-multipliers',
  SegmentMultipliers = 'segment-multipliers',
  ReportCheck = 'report-check',
  ReportData = 'report-data',
  ReportDataInfinite = 'report-data-infinite',
  ReportList = 'report-list',
  ReportMeta = 'report-meta',
  ReportSettingsList = 'report-settings-list',
}

export default { AppQueryKey };

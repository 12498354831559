import type { AxiosResponse } from 'axios';

import type {
  FetchAdditionalDataAddResponse,
  FetchAdditionalDataResponse,
  FetchAdditionalDataTypesResponse,
} from '@/services/api';
import { ApiClient } from '../../apiClient';

export class AdditionalDataApiClient extends ApiClient {
  async fetchAdditionalData(payload: {
    spaceId: number;
  }): Promise<AxiosResponse<FetchAdditionalDataResponse>> {
    const { spaceId } = payload;

    const res = await this.httpClient.get<FetchAdditionalDataResponse>(
      `/api/${spaceId}/additional_data/files`,
    );

    return res;
  }

  async fetchAdditionalDataTypes(payload: {
    spaceId: number;
  }): Promise<AxiosResponse<FetchAdditionalDataTypesResponse>> {
    const { spaceId } = payload;

    const res = await this.httpClient.get<FetchAdditionalDataTypesResponse>(
      `/api/${spaceId}/additional_data/types`,
    );

    return res;
  }

  async addAdditionalData(payload: {
    spaceId: number;
    dataType: string;
    file: File;
  }): Promise<AxiosResponse<FetchAdditionalDataAddResponse>> {
    const { spaceId, dataType, file } = payload;

    const res = await this.httpClient.postForm<FetchAdditionalDataAddResponse>(
      `/api/${spaceId}/additional_data/upload/${dataType}`,
      {
        file,
      },
    );

    return res;
  }

  getUriDownloadAdditionalData(payload: {
    spaceId: number;
    fileId: string;
  }): string {
    const { spaceId, fileId } = payload;

    const res = this.httpClient.getUri({
      url: `/api/${spaceId}/additional_data/download/${fileId}`,
    });

    return res;
  }
}

export const additionalDataApiClient = new AdditionalDataApiClient();

export default { AdditionalDataApiClient, additionalDataApiClient };

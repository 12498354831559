export default {
  noData: 'У проекта нет дополнительных данных',
  tipNoData: `Чтобы отобразились отчёты нужно<br/>подключить рекламные кабинеты`,
  uploadZone: 'Перетащите файл сюда или нажмите на',
  uploadButton: 'Загрузить',
  columns: {
    type: 'Тип',
    name: 'Название файла',
    uploadedAt: 'Дата загрузки',
    uploadedBy: 'Автор',
    status: 'Статус',
  },
  statuses: {
    ok: 'Обработан',
    error: 'Ошибка',
    warning: 'Обработан с замечаниями',
    uploaded: 'Загружен',
  },
  statusesTip: {
    uploaded: 'Файл загружен, но ещё не обработан',
    ok: 'Файл загружен и обработан успешно',
  },
  notification: {
    success: 'Файл успешно добавлен',
    error: 'Не удалось распознать файл: {name}',
  },
};

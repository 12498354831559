export default {
  noData: 'The project has no additional data',
  tipNoData: `To display reports, you need to<br/>connect advertising accounts`,
  uploadZone: 'Drag the file here or click on',
  uploadButton: 'Upload',
  columns: {
    type: 'Type',
    name: 'File name',
    uploadedAt: 'Upload date',
    uploadedBy: 'Author',
    status: 'Status',
  },
  statuses: {
    ok: 'Active',
    error: 'Error',
    warning: 'Processing errors',
    uploaded: 'Uploaded',
  },
  statusesTip: {
    uploaded: 'File uploaded but not processed yet',
    ok: 'File uploaded and processed successfully',
  },
  notification: {
    success: 'File successfully added',
    error: 'Failed to recognize file: {name}',
  },
};

declare module 'vue-router' {
  interface RouteMeta {
    layout?: string;
    public?: boolean;
  }
}

export enum RouteName {
  Index = 'index',
  Main = 'main',
  Login = 'login',
  LoginInit = 'login-init',
  Logout = 'logout',
  Spaces = 'spaces',
  Space = 'space',
  SpaceProjects = 'space-projects',
  SpaceUsers = 'space-users',
  SpaceSettings = 'space-settings',
  Project = 'project',
  ProjectReports = 'project-reports',
  ProjectAdsCabinets = 'project-ads-cabinets',
  ProjectUsers = 'project-users',
  ProjectMagicLinks = 'project-magic-links',
  ProjectSettings = 'project-settings',
  Report = 'report',
  AdsAccountJoin = 'ads-account-join',
  AdsAccountSuccess = 'ads-account-success',
  Dashboard = 'dashboard',
  AdditionalData = 'additional-data',
  AccessDenied = 'access-denied',
  NotFound = 'not-found',
}

export default { RouteName };
